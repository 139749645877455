<template>
  <div class="bg-white shadow rounded px-3 pt-3 pb-5 border border-white">
    <div class="flex justify-between">
      <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">
        {{ task.Kommentar }}
      </p>

      <v-icon small class="mr-2" @click="$emit('on-edit', task)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="$emit('on-delete', task)"> mdi-delete </v-icon>

      <img
        class="w-6 h-6 rounded-full ml-3"
        src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png"
        alt="Avatar"
      />
    </div>
    <div class="flex mt-4 justify-between items-center">
      <span class="text-sm text-gray-600">{{ task.Kommentar }}</span>
      <badge v-if="task.TaskStatusID" :color="badgeColor">{{
        task.TaskStatusID
      }}</badge>
    </div>
  </div>
</template>
<script>
import Badge from "./Badge.vue";
export default {
  components: {
    Badge,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    badgeColor() {
      const mappings = {
        "To do": "purple",
        Doing: "teal",
        Blocked: "blue",
        Done: "green",
        Aborted: "teal",
      };
      // const mappings = {
      //   1: "purple",
      //   2: "teal",
      //   3: "blue",
      //   4: "green",
      //   5: "teal"
      // };
      return mappings[this.task.TaskStatusID] || mappings.default;
    },
  },
};
</script>
