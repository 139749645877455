<template>
  <v-container>
    <my-toolbar-progress-bar
      :isLoading="isLoading"
      :color="toolbareColor"
      slot="extension"
    />
    <template>
      <v-bottom-navigation color="primary" horizontal>
        <v-btn
          :to="{
            name: 'Dialog',
            params: { TaskBehandlingID: this.currentTaskBehandlingID },
          }"
          :disabled="this.currentTaskBehandlingID == -1"
        >
          <span>Dialog</span>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Søk"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <template>
      <div id="app">
        <div class="flex justify-center">
          <div class="min-h-screen flex overflow-x-scroll py-12">
            <div
              v-for="column in columns"
              :key="column.title"
              class="bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-4"
            >
              <p
                class="text-gray-700 font-semibold font-sans tracking-wide text-sm"
              >
                {{ column.title }}
              </p>
              <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
              <draggable
                :list="column.tasks"
                :animation="200"
                ghost-class="ghost-card"
                group="tasks"
                @change="log"
              >
                <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                <task-card
                  v-for="task in column.tasks"
                  :key="task.TaskBehandlingID"
                  :task="task"
                  class="mt-3 cursor-move"
                  @on-edit="onEdit"
                  @on-delete="onDelete"
                ></task-card>
                <!-- </transition-group> -->
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </template>

    <v-data-table
      :headers="headers"
      :items="dataList"
      class="elevation-1"
      @click:row="rowClick"
      item-key="TaskBehandlingID"
      single-select
      :search="search"
    >
      <template #top>
        <v-toolbar flat color="white">
          <v-toolbar-title
            >Oppgave behandling - {{ currentHeading }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template #activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Ny</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <dlgTaskBehandling
                      v-if="dialog"
                      v-model="editedItem"
                      :editMode="editedIndex"
                      :keySprintID="keySprintID"
                    />
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Avbryt</v-btn>
                <v-btn color="blue darken-1" text @click="save">Lagre</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template #no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
//https://www.binarcode.com/blog/building-animated-draggable-interfaces-with-vuejs-and-tailwind/
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";
import MyToolbarProgressBar from "@/components/MyToolbarProgressBar.vue";
import draggable from "vuedraggable";
import TaskCard from "../components/TaskCard.vue";

// <ToDo v-model="dataList"
//   :keySprintID="keySprintID"
//   @on-edit="onEdit"
//   @on-delete="onDelete">
// </ToDo>
export default {
  extends: KeyValue,
  components: {
    //ToDo: () => import("../components/ToDo"),
    dlgTaskBehandling: () => import("../dialog/dlgTaskBehandling"),
    MyToolbarProgressBar,
    TaskCard,
    draggable,
  },
  data() {
    return {
      search: "",
      isLoading: false,
      toolbareColor: "red",
      keySprintID: -1,
      dialog: false,
      headers: [
        { text: "Sprint", value: "SprintID" },
        { text: "Oppgave", value: "TaskID" },
        { text: "Oppgave status", value: "TaskStatusID" },
        { text: "Bruker", value: "BrukerID" },
        { text: "Kommentar", value: "Kommentar" },
        { text: "Aksjon", value: "actions", sortable: false },
      ],
      currentTaskBehandlingID: -1,
      currentHeading: "",
      dataList: [],
      editedIndex: -1,
      editedItem: {
        TaskBehandlingID: "",
        SprintID: "",
        TaskID: "",
        TaskStatusID: "",
        BrukerID: "",
        Kommentar: "",
      },
      defaultItem: {
        TaskBehandlingID: "",
        SprintID: "",
        TaskID: "",
        TaskStatusID: "",
        BrukerID: "",
        Kommentar: "",
      },
      columns: [],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ny" : "Endre";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.keySprintID = this.$route.params.SprintID;
    this.defaultItem.SprintID = this.keySprintID;
    this.initialize();
  },

  methods: {
    rowClick: function (item, row) {
      row.select(true);
      //this.editedIndex = this.dataList.indexOf(item);
      this.currentTaskBehandlingID = item.TaskBehandlingID;
      this.currentHeading = item.Kommentar;
      this.$root.$emit("TaskBehandling", item.TaskBehandlingID, item.Kommentar);
    },
    initEditItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    initialize() {
      this.isLoading = true;
      //this.GetTaskStatusCode();
      this.GetData(this.keySprintID);
      this.initEditItem();
      this.isLoading = false;
    },
    GetTaskBehandling(TaskBehandlingID) {
      axios({
        method: "get",
        params: { TaskBehandlingID: TaskBehandlingID },
        url: DataSourceURL + "/TaskBehandling/GetTaskBehandling",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItem = res.data.DataList;
            this.dialog = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /TaskBehandling/GetTaskBehandling. See details: ${err}`
          );
        });
    },

    GetData(keySprintID) {
      axios({
        method: "get",
        params: { SprintID: keySprintID },
        url: DataSourceURL + "/TaskBehandling/GetData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.dataList = res.data.DataList;
            this.myGetTaskStatusCode(); //FIll drag / drop stucture
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /TaskBehandling/GetData. See details: ${err}`
          );
        });
    },

    editItem(item) {
      this.editedIndex = this.dataList.indexOf(item);
      this.GetTaskBehandling(item.TaskBehandlingID);
      //this.editedItem = Object.assign({}, item)
      //this.dialog = true
    },

    DeleteData(index, TaskBehandlingID) {
      axios({
        method: "delete",
        url: DataSourceURL + "/TaskBehandling/DoDelete",
        params: { TaskBehandlingID: TaskBehandlingID },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "DELETE",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.dataList.splice(index, 1);
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /TaskBehandling/Delete. See details: ${err}`
          );
        });
    },

    deleteItem(item) {
      const index = this.dataList.indexOf(item);
      if (
        confirm(
          "Are you sure you want to delete this item? " + item.SprintID
        ) == true
      ) {
        this.DeleteData(index, item.TaskBehandlingID);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.initEditItem();
      });
    },

    DoSave() {
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editedIndex > -1) {
        myUrl = DataSourceURL + "/TaskBehandling/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/TaskBehandling/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: this.editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK

              //Ordre kode felt
              let OldTaskStatusID;
              if (this.editedIndex > -1) {
                OldTaskStatusID = this.dataList[this.editedIndex].TaskStatusID;
              }
              this.editedItem.TaskStatusID = this.FindDescription(
                this.editedItem.TaskStatusID,
                this.TaskStatusItems
              );
              this.editedItem.TaskID = this.FindDescription(
                this.editedItem.TaskID,
                this.TaskItemsSprintID
              );
              if (this.editedIndex > -1) {
                Object.assign(this.dataList[this.editedIndex], this.editedItem); //Oppdatering
                if (
                  OldTaskStatusID !==
                  this.dataList[this.editedIndex].TaskStatusID
                ) {
                  //Move into correct drag stucture
                  this.MoveTaskIntoCorrectStructure(
                    this.editedItem.TaskBehandlingID
                  );
                }
              } else {
                this.editedItem.TaskBehandlingID = res.data.ID;
                this.dataList.push(this.editedItem); //Ny
                //Add into correct drag stucture
                this.AddTaskIntoCorrectStructure(this.editedItem);
              }
              this.close();
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    save() {
      //Set key fields
      this.editedItem.SprintID = this.keySprintID;

      this.DoSave();
    },

    //DRAG **********************************************
    onEdit(task) {
      this.editItem(task);
      //alert(`Editing ${task.TaskBehandlingID}`);
    },
    onDelete(task) {
      this.deleteItem(task);
      //alert(`Deleting ${task.TaskBehandlingID}`);
    },
    myGetTaskStatusCode() {
      if (this.TaskStatusItems.length == 0) {
        //Lazy load
        axios({
          method: "get",
          url: DataSourceURL + "/code/GetTaskStatusCode",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "GET",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              console.log("myGetTaskStatusCode() {");
              this.TaskStatusItems = res.data.DataList;
              this.SetColumnsPart1();

              let i = 0;
              for (i = 0; i < this.columns.length; i++) {
                this.columns[i].tasks = this.myTaskItems(this.columns[i].title);
              }
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(
              `There was an error in reading /code/GetTaskStatusCode. See details: ${err}`
            );
          });
      }
    },

    DoSaveStatusUpdate(TaskBehandlingID, TaskStatusID) {
      let submit = true;

      submit = true;

      if (submit) {
        axios({
          method: "post",
          url: DataSourceURL + "/TaskBehandling/DoSaveStatusUpdate",
          data: {
            data: {
              TaskBehandlingID: TaskBehandlingID,
              TaskStatusID: TaskStatusID,
            },
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (res.data.ErrorStatus) {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    SetColumnsPart1() {
      let i = 0;
      for (i = 0; i < this.TaskStatusItems.length; i++) {
        this.columns.push({
          title: this.TaskStatusItems[i].Description,
          ID: this.TaskStatusItems[i].Value,
          tasks: [],
        });
      }
    },
    myTaskItems: function (TaskStatusID) {
      return this.dataList.filter((task) => task.TaskStatusID === TaskStatusID);
    },
    log: function (evt) {
      window.console.log(evt);
      if (evt.removed) {
        this.UpdateTaskStatusID(evt.removed.element.TaskBehandlingID, true);
      }
    },
    UpdateTaskStatusID(TaskBehandlingID, save) {
      let i = 0;
      let a = [];
      for (i = 0; i < this.columns.length; i++) {
        a = this.columns[i].tasks.filter(
          (task) => task.TaskBehandlingID === TaskBehandlingID
        );
        if (a.length > 0) {
          a[0].TaskStatusID = this.columns[i].title;
          if (save)
            this.DoSaveStatusUpdate(a[0].TaskBehandlingID, this.columns[i].ID);
          return;
        }
      }
    },
    MoveTaskIntoCorrectStructure(TaskBehandlingID) {
      let i = 0;
      let j = 0;
      let a = [];
      for (i = 0; i < this.columns.length; i++) {
        a = this.columns[i].tasks.filter(
          (task) => task.TaskBehandlingID === TaskBehandlingID
        );
        if (a.length > 0) {
          for (j = 0; j < this.columns.length; j++) {
            if (a[0].TaskStatusID === this.columns[j].title) {
              if (i !== j) {
                const index = this.columns[i].tasks.indexOf(a[0]);
                this.columns[i].tasks.splice(index, 1);
                this.columns[j].tasks.push(a[0]);
                return;
              }
            }
          }
        }
      }
    },
    AddTaskIntoCorrectStructure(editedItem) {
      const a = this.columns.filter(
        (task) => task.title === editedItem.TaskStatusID
      );
      a[0].tasks.push(editedItem);
    },
  },
};
</script>

<style scoped>
@import "https://unpkg.com/tailwindcss@1.9.2/dist/tailwind.min.css";

.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
</style>
