var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('my-toolbar-progress-bar',{attrs:{"slot":"extension","isLoading":_vm.isLoading,"color":_vm.toolbareColor},slot:"extension"}),[_c('v-bottom-navigation',{attrs:{"color":"primary","horizontal":""}},[_c('v-btn',{attrs:{"to":{
          name: 'Dialog',
          params: { TaskBehandlingID: this.currentTaskBehandlingID },
        },"disabled":this.currentTaskBehandlingID == -1}},[_c('span',[_vm._v("Dialog")]),_c('v-icon',[_vm._v("mdi-heart")])],1)],1)],_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),[_c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"flex justify-center"},[_c('div',{staticClass:"min-h-screen flex overflow-x-scroll py-12"},_vm._l((_vm.columns),function(column){return _c('div',{key:column.title,staticClass:"bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-4"},[_c('p',{staticClass:"text-gray-700 font-semibold font-sans tracking-wide text-sm"},[_vm._v(" "+_vm._s(column.title)+" ")]),_c('draggable',{attrs:{"list":column.tasks,"animation":200,"ghost-class":"ghost-card","group":"tasks"},on:{"change":_vm.log}},_vm._l((column.tasks),function(task){return _c('task-card',{key:task.TaskBehandlingID,staticClass:"mt-3 cursor-move",attrs:{"task":task},on:{"on-edit":_vm.onEdit,"on-delete":_vm.onDelete}})}),1)],1)}),0)])])],_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"item-key":"TaskBehandlingID","single-select":"","search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Oppgave behandling - "+_vm._s(_vm.currentHeading))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Ny")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.dialog)?_c('dlgTaskBehandling',{attrs:{"editMode":_vm.editedIndex,"keySprintID":_vm.keySprintID},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Avbryt")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Lagre")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }